<template>
  <main class="main page-404">
    <h1 class="page-404__title">404</h1>
    <img src="/static/images/404.png" class="page-404__image" alt="404" />
    <span class="page-404__subtitle">Упсс...</span>
    <p class="page-404__text">Что-то пошло не так, данной страницы не существует.</p>
    <router-link :to="{ name: 'home' }" class="btn btn--main">Вернуться на главную</router-link>
  </main>
</template>

<script>
export default {
  name: "NotFound",
  async asyncData({ res }) {
    if (res) {
      res.status(404);
    }
  },
};
</script>

<style lang="stylus">
.page-404 {
  background var(--white)
  padding 60px 32px
  display grid
  justify-content center
  align-items center
  position relative
  +below(640px) {
    padding 0 20px 20px
  }

  &__title {
    absolute left top right
    text-align center
    font-weight: bold;
    font-size: 18em;
    line-height: 349px;
    color: var(--main_color);
    margin 0
    +below(640px) {
      font-size: 6em;
      line-height: 116px;
    }
  }

  &__image {
    object-fit contain
    object-position center
    max-width 500px
    height 100%
    z-index 1
    margin 30px auto 0
    +below(640px) {
      max-width 250px
    }
  }

  &__subtitle {
    margin auto
    font-weight: bold;
    font-size: 3em;
    line-height: 58px;
    text-align: center;
    color: var(--main_color);
    +below(640px) {
      font-size: 2.25em;
      line-height: 44px;
    }
  }

  &__text {
    font-weight: normal;
    font-size: 1.5em;
    line-height: 29px;
    text-align: center;
    color: var(--main_color);
    max-width 345px
    width 100%
    margin 0 auto 30px
    +below(640px) {
      margin-bottom 25px
      font-size: 1.125em;
      line-height: 22px;
    }
  }

  .btn {
    margin auto
  }
}
</style>
